<template>
  <div>
    <b-card title="Gestion de Recepciones">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="8">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('venta-registrar')"
                :disabled="buttonDisabledTransferencia"
                @click="verPdfRecepcion(item.id)"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span class="align-middle">VER RECEPCIÓN</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="verPdf(item.id)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER TRANSFERENCIA</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>

              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="abrirModalVenta"
                :disabled="buttonDisabledTransferencia"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span class="align-middle">CANCELAR RECEPCIÓN</span>
                </span>
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" md="4">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="6" class="mt-1 mt-md-0">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <ListadoRecepcion
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemVenta="selectItemVenta"
        />
      </b-card-text>

      <!-- <b-card-text>
        <b-row>
          <b-col cols="6" md="6">
            <span class="text-nowrap text-bold">
              <strong>
                Mostrando {{ from }} - {{ to }} de {{ total }} Registros
              </strong>
            </span>
          </b-col>
          <b-col cols="6" md="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
              align="right"
              size="sm"
              class="my-0"
            >
              <template #prev-text>
                <feather-icon size="18" icon="ChevronLeftIcon" />
              </template>
              <template #next-text>
                <feather-icon size="18" icon="ChevronRightIcon" />
              </template>
              <template #last-text>
                <feather-icon size="18" icon="ChevronsRightIcon" />
              </template>
              <template #first-text>
                <feather-icon size="18" icon="ChevronsLeftIcon" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-text> -->
    </b-card>
    <ModalPdfTransferencia
      :mostrarAcciones="item.recepcion_id === null ? true : false"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
      @handle-ok="confirmarRecepcion(item)"
    />
  </div>
</template>

  <script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ListadoRecepcion from "../views/ListadoRecepcion.vue";
import TransferenciaServices from "../services/index";
import ModalPdfTransferencia from "../components/ModalPdfTransferencia.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    ListadoRecepcion,
    BRow,
    BCol,
    BPagination,
    ModalPdfTransferencia,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      buttonDisabledTransferencia: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
      tipo_transaccion: "VENTA",
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      // venta
      mostradoModalCompraVentaCotizacion: false,
    };
  },
  created() {},
  methods: {
    // SERVICES
    async listar() {
      this.loading = true;
      try {
        const response = await TransferenciaServices.getRecepciones(
          this.mixing.sede_id
        );
        this.items = response.data;
        this.item = [];
        this.buttonDisabled = true;
        this.buttonDisabledTransferencia = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await TransferenciaServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdfRecepcion(id) {
      try {
        const response = await TransferenciaServices.findOnePdfRecepcion(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
    },
    async eliminar(id) {
      try {
        const response = await VentaServices.destroy(id);
        this.$bvToast.toast("Se anulo correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItemVenta(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
      this.buttonDisabledTransferencia = !this.item.transaccion_recepcion;
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    abrirModalVenta() {
      this.mostradoModalCompraVentaCotizacion = true;
    },
    cerrarModalCompraVenta() {
      this.listar();

      this.mostradoModalCompraVentaCotizacion = false;
    },
    confirmarRecepcion(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea realizar la recepción.", {
          title: "¿REALIZAR RECEPCIÓN?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Si, recepcionar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            const {
              id,
              detalle_transaccion: detalleTransaccion,
              ...transaccionData
            } = item.transaccion_transferencia;

            const data = {
              recepcion_transferencia_id: item.id,
              sede_id: item.sede.id,
              sede_objetivo_id: item.sede_objetivo.id,
              ...transaccionData,
              detalleTransaccion: detalleTransaccion.map((detalle) => {
                const { id, producto_id, ...det } = detalle;
                return {
                  id: producto_id,
                  ...det,
                };
              }),
            };

            this.registrarRecepcion(data);
          }
        });
    },
    async registrarRecepcion(data) {
      try {
        const response = await TransferenciaServices.storeRecepcion(data);

        this.$bvToast.toast("Se realizo la recepción correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });

        this.listar();

        this.cerrarModalPdf();

        this.verPdfRecepcion(response.data.recepcion.id);
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

  <style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
